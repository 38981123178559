import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPayrolls(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://api.sams-ph.com/api/paydetail", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
      // 'https://api.sams-ph.com/api/paydetail'
      // http://localhost:3333/api/paydetail
      // /apps/payroll/payrolls
    },
    fetchPayroll(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://api.sams-ph.com/api/paydetail/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // fetchClients() {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('https://api.sams-ph.com/api/clients')
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
};
