<template>
  <section class="payroll-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="payrollData === undefined">
      <h4 class="alert-heading">Error fetching payroll data</h4>
      <div class="alert-body">
        No payroll found with this payroll id. Check
        <b-link class="alert-link" :to="{ name: 'apps-payroll-list' }">
          Payroll List
        </b-link>
        for other payrolls.
      </div>
    </b-alert>

    <b-row v-if="payrollData" class="payroll-preview">
      <!-- Col: Left (Payroll Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="payroll-preview-card">
          <!-- Header -->
          <b-card-body class="payroll-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column payroll-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <!-- <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary payroll-logo">
                    SafeGuard Investigation and Security Agency, Inc.
                  </h3>
                </div> -->
                <h4 class="payroll-title">
                  SafeGuard Investigation and Security Agency, Inc.
                </h4>
                <!-- <p class="card-text mb-25">
                  San Diego County, CA 91905, USA
                </p> -->
                <!-- <p class="card-text mb-25">
                  San Diego County, CA 91905, USA
                </p> -->
                <!-- <p class="card-text mb-0">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p> -->
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="payroll-title">
                  Payslip
                  <!-- <span class="payroll-number">#{{ payrollData.id }}</span> -->
                </h4>
                <!-- <div class="payroll-date-wrapper">
                  <p class="payroll-date-title">
                    Date Issued:
                  </p>
                  <p class="payroll-date">
                    {{ payrollData.issuedDate }}
                  </p>
                </div> -->
                <!-- <div class="payroll-date-wrapper">
                  <p class="payroll-date-title">
                    Due Date:
                  </p>
                  <p class="payroll-date">
                    {{ payrollData.issuedDate }}
                  </p>
                </div> -->
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="payroll-spacing" />

          <!-- Payroll Client & Payment Details -->
          <b-card-body v-if="payrollData" class="payroll-padding pt-0">
            <b-row class="payroll-spacing">
              <!-- Col: Payroll To -->
              <b-col cols="12" xl="6" class="p-0">
                <!-- <h6 class="mb-2">
                  Payroll To:
                </h6> -->
                <h6 class="mb-25">Employee</h6>
                <p class="card-text mb-25">
                  {{ payrollData.employee_no }} -
                  {{ payrollData.employee_name }}
                </p>
                <h6 class="mb-25">Client</h6>
                <p class="card-text mb-25">
                  {{ payrollData.client_no }} - {{ payrollData.client_name }}
                </p>
                <h6 class="mb-25">Period : {{ payrollData.pay_period }}</h6>
                <p class="card-text mb-25">
                  {{ formatDate(payrollData.pay_start_date) }} -
                  {{ formatDate(payrollData.pay_end_date) }}
                </p>
                <p class="card-text mb-0">
                  <!-- {{ payrollData.client.companyEmail }} -->
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Employee Information:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">TIN:</td>
                        <td>
                          <span class="font-weight-bold">{{
                            payrollData.tax_no
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">SSS:</td>
                        <td>{{ payrollData.sss_no }}</td>
                      </tr>
                      <!-- <tr>
                        <td class="pr-1">
                          TaxCode:
                        </td>
                        <td>{{ payrollData.taxcode }}</td>
                      </tr> -->
                      <tr>
                        <td class="pr-1">PhilHealth:</td>
                        <td>{{ payrollData.ph_no }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Pag-IBIG:</td>
                        <td>{{ payrollData.hdmf_no }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Payroll Description: Table -->
          <!-- <b-table-lite
            responsive
            :items="payrollDescription"
            :fields="['Earnings', 'hours', 'total', 'Deductions', 'rate', 'balance']"
          >
            <template #cell(Earnings)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.Earnings }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.Deductions }}
              </b-card-text>
            </template>
          </b-table-lite> -->

          <!-- Payroll Description: Total -->
          <b-card-body class="payroll-padding pb-0">
            <b-row>
              <!-- Col: Earning -->
              <b-col cols="6" class="mt-md-0 mt-0" order="1" order-md="1">
                <div class="payroll-total-wrapper">
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Basic Pay:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.reg_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.reg_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Overtime:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.ot_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.ot_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div
                    v-if="payrollData.nd_hours === 0"
                    class="payroll-total-item"
                  >
                    <p class="payroll-total-title">NightDiff:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.nd_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.nd_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">RestDay:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.rd_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.rd_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">RestDay OT:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.rdot_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.rdot_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div
                    v-if="payrollData.rdnd_hours != 0"
                    class="payroll-total-item"
                  >
                    <p class="payroll-total-title">RestDay NDiff:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.rdnd_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.rdnd_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Special Holiday:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.sh_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.sh_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Special OT:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.shot_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.shot_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div
                    v-if="payrollData.shnd_hours != 0"
                    class="payroll-total-item"
                  >
                    <p class="payroll-total-title">Special NDiff:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.shnd_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.shnd_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Legal Holiday:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.lh_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.lh_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Legal OT:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.lhot_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.lhot_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div
                    v-if="payrollData.lhnd_hours != 0"
                    class="payroll-total-item"
                  >
                    <p class="payroll-total-title">Legal NDiff:</p>
                    <p class="payroll-total-hours">
                      {{ payrollData.lhnd_hours }}
                    </p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.lhnd_amount).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Others:</p>
                    <p class="payroll-total-amount">
                      {{
                        Number(payrollData.earn_01) +
                        Number(payrollData.earn_02) +
                        Number(payrollData.earn_03) +
                        Number(payrollData.earn_04) +
                        Number(payrollData.earn_05) +
                        Number(payrollData.earn_06) +
                        Number(payrollData.earn_07) +
                        Number(payrollData.earn_08) +
                        Number(payrollData.earn_09) +
                        Number(payrollData.earn_10) +
                        Number(payrollData.earn_11) +
                        Number(payrollData.earn_12) +
                        Number(payrollData.earn_13) +
                        Number(payrollData.earn_14) +
                        Number(payrollData.earn_15) +
                        Number(payrollData.earn_16) +
                        Number(payrollData.earn_17) +
                        Number(payrollData.earn_18) +
                        Number(payrollData.earn_19) +
                        Number(payrollData.earn_20)
                      }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Total Earnings:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.total_earnings).toLocaleString() }}
                    </p>
                  </div>
                </div>
              </b-col>

              <!-- Col: Balance -->
              <b-col
                cols="6"
                class="mt-md-6 mt-0 d-flex justify-content-end"
                order="2"
                order-md="2"
              >
                <div class="payroll-total-wrapper">
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">SSS Premium:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.sss_employee).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">PhilHealth:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.ph_employee).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Pag-IBIG:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.hdmf_employee).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">W/Tax:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.w_tax).toLocaleString() }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">SSS Loan:</p>
                    <p class="payroll-total-amount">
                      {{ payrollData.dedn_01 }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">HDMF Loan:</p>
                    <p class="payroll-total-amount">
                      {{ payrollData.dedn_02 }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">HDMF Calamity:</p>
                    <p class="payroll-total-amount">
                      {{ payrollData.dedn_03 }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Cash Advance:</p>
                    <p class="payroll-total-amount">
                      {{ payrollData.dedn_04 }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">CB Deposit:</p>
                    <p class="payroll-total-amount">
                      {{ payrollData.dedn_05 }}
                    </p>
                  </div>
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Others:</p>
                    <p class="payroll-total-amount">
                      {{
                        Number(
                          payrollData.dedn_06 +
                            payrollData.dedn_07 +
                            payrollData.dedn_08 +
                            payrollData.dedn_09 +
                            payrollData.dedn_10 +
                            payrollData.dedn_11 +
                            payrollData.dedn_12 +
                            payrollData.dedn_13 +
                            payrollData.dedn_14 +
                            payrollData.dedn_15 +
                            payrollData.dedn_16 +
                            payrollData.dedn_17 +
                            payrollData.dedn_18 +
                            payrollData.dedn_19 +
                            payrollData.dedn_20 +
                            payrollData.dedn_21 +
                            payrollData.dedn_22 +
                            payrollData.dedn_23 +
                            payrollData.dedn_24 +
                            payrollData.dedn_25 +
                            payrollData.dedn_26 +
                            payrollData.dedn_27 +
                            payrollData.dedn_28 +
                            payrollData.dedn_29 +
                            payrollData.dedn_30
                        )
                      }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Total Deductions:</p>
                    <p class="payroll-total-amount">
                      {{
                        Number(payrollData.total_deductions).toLocaleString()
                      }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="payroll-spacing" />

          <b-card-body class="payroll-padding pb-0">
            <b-row>
              <b-col
                cols="6"
                md="6"
                class="mt-md-0 mt-0"
                order="1"
                order-md="1"
              />
              <!-- Col: Total -->
              <b-col
                cols="6"
                md="6"
                class="mt-md-6 mt-0 d-flex justify-content-end"
                order="2"
                order-md="2"
              >
                <div class="payroll-total-wrapper">
                  <div class="payroll-total-item">
                    <p class="payroll-total-title">Netpay:</p>
                    <p class="payroll-total-amount">
                      {{ Number(payrollData.net_pay).toLocaleString() }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <h4 class="payroll-title">
                SafeGuard Investigation and Security Agency, Inc.
              </h4>
            </b-row>
          </b-card-body>
          <!-- Note -->
          <!-- <b-card-body class="payroll-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span>
          </b-card-body> -->
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="payroll-actions">
        <b-card>
          <!-- Button: Send Payroll -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-payroll
            variant="primary"
            class="mb-75"
            block
          >
            Send Payroll
          </b-button> -->

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :to="{
              name: 'apps-employees-list',
              params: { id: $route.params.id },
            }"
          >
            Close
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printPayroll"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'apps-payroll-edit', params: { id: $route.params.id } }"
          >
            Edit
          </b-button> -->

          <!-- Button: Add Payment -->
          <!-- <b-button
            v-b-toggle.sidebar-payroll-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            Add Payment
          </b-button> -->
        </b-card>
      </b-col>
    </b-row>

    <!-- <payroll-sidebar-send-payroll /> -->
    <!-- <payroll-sidebar-add-payment /> -->
  </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import payrollStoreModule from "../payrollStoreModule";
// import PayrollSidebarSendPayroll from '../PayrollSidebarSendPayroll.vue'
// import PayrollSidebarAddPayment from '../PayrollSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    // PayrollSidebarAddPayment,
    // PayrollSidebarSendPayroll,
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
  setup() {
    const payrollData = ref(null);
    const paymentDetails = ref({});

    // Payroll Description
    // ? Your real data will contain this information
    // const payrollDescription = [
    //   {
    //     Earnings: 'Basic Pay',
    //     Deductions: 'SSS Premium',
    //     rate: '60.00',
    //     hours: '120',
    //     total: '8,055.00',
    //     balance: '',
    //   },
    //   {
    //     Earnings: 'Overtime',
    //     Deductions: 'PhilHealth',
    //     rate: '60.00',
    //     hours: '48',
    //     total: '4,027.50',
    //     balance: '',
    //   },
    //   {
    //     Earnings: 'Night Diff',
    //     Deductions: 'Pag-IBIG',
    //     rate: '60.00',
    //     hours: '48',
    //     total: '4,027.50',
    //     balance: '',
    //   },
    // ]

    const PAYROLL_APP_STORE_MODULE_NAME = "app-payroll";

    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME))
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME))
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-payroll/fetchPayroll", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        payrollData.value = response.data.payroll;
        paymentDetails.value = response.data.paymentDetails;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          payrollData.value = undefined;
        }
      });

    const printPayroll = () => {
      window.print();
    };

    return {
      payrollData,
      paymentDetails,
      // payrollDescription,
      printPayroll,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-payroll.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Payroll Specific Styles
  .payroll-preview-wrapper {
    .row.payroll-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .payroll-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .payroll-actions {
      display: none;
    }
  }
}
</style>
